<template>
<div>
  <b-card no-body>
    <b-tabs card @activate-tab="refresher" v-model="activeTab">
      <b-tab title="Профиль">
        <b-card-group deck class="flex-wrap">
          <b-card no-body v-for="user,idx in user_list" :key="idx" class="mb-2"
                  style="min-width: 24rem;max-width: 32rem;">
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <h6 class="mb-0">{{user.name}}</h6>
                <b-form-checkbox size="sm" v-model="user.frozen" @change="frozen(user)"> Заморожен </b-form-checkbox>
                <span>{{user.last_event | timestamp}}</span>
              </div>
            </template>
            <b-card-body class="d-flex flex-column">
              <b-card-text>
                <b-row>
                  <b-col cols="4" class="text-right">Кмдр:</b-col>
                  <b-col>{{user.profile.name}}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4" class="text-right">Кредиты:</b-col>
                  <b-col>{{user.profile.credits | num2text}}</b-col>
                </b-row>
                <b-row align-v="center">
                  <b-col cols="4" class="text-right">Корабль:</b-col>
                  <b-col>
                    <div v-if="user.ship.ship_id >= 0">
                      <b-button variant="info" @click="showLoadout(user)">
                        {{user.ship.type}}
                        <span v-show="user.ship.name"> "{{user.ship.name}}" </span>
                        <span v-show="user.ship.identifier"> ({{user.ship.identifier}}) </span>
                      </b-button>
                    </div>
                    <div v-else>
                      {{user.ship.type}}
                      <span v-show="user.ship.name"> "{{user.ship.name}}" </span>
                      <span v-show="user.ship.identifier"> ({{user.ship.identifier}}) </span>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="4" class="text-right">Система:</b-col>
                  <b-col>
                    <div v-if="user.profile.system_id">
                      <b-link :to="`/system/${user.profile.system_id}`">{{user.profile.system}}</b-link>
                    </div>
                    <div v-else> {{user.profile.system}} </div>
                  </b-col>
                </b-row>
                <b-row v-if="user.profile.starport">
                  <b-col cols="4" class="text-right">Пристыкован:</b-col>
                  <b-col>
                    <div v-if="user.profile.starport_id">
                      <b-link :to="`/station/${user.profile.starport_id}`">{{user.profile.starport}}</b-link>
                    </div>
                    <div v-else> {{user.profile.starport}} </div>
                  </b-col>
                </b-row>
                <b-row v-if="user.fleetcarrier.name">
                  <b-col cols="4" class="text-right">Авик:</b-col>
                  <b-col>
                    <b-link :to="`/fc/${user.fleetcarrier.callsign}`">{{user.fleetcarrier.name}}</b-link>
                  </b-col>
                </b-row>
              </b-card-text>

                <b-button-group class="mt-auto d-flex justify-content-between align-items-center">
                  <b-button v-if="user.rank" size="sm" variant="outline-info" @click="showRanks(user)">Ранги</b-button>
                  <b-button size="sm" variant="outline-info" @click="showReps(user)">Репа</b-button>
                  <b-button :to="`/statistics/${user.id}`" size="sm" variant="outline-info">Стат</b-button>
                  <b-button :to="`/materials/${user.id}`" size="sm" variant="outline-info">Материалы</b-button>
                  <b-button :to="`/fleet/${user.id}`" size="sm" variant="outline-info">Флот</b-button>
                  <b-button :to="`/modules/${user.id}`" size="sm" variant="outline-info">Модули</b-button>
                </b-button-group>
            </b-card-body>
            
            <template #footer >
              <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                <div class="d-flex justify-content-start align-items-center">
                  <b-button :disabled="busy" size="sm" variant="primary" @click="getAll(user)"> Всё </b-button>
                  <b-button :disabled="busy" size="sm" class="ml-1" variant="primary" @click="getProfile(user)"> Профиль </b-button>
                  <b-button :disabled="busy" size="sm" class="ml-1" variant="primary" @click="getJournal(user)"> Журнал </b-button>
                  <b-button :disabled="busy" size="sm" class="ml-1" variant="primary" @click="getMarket(user)"> Рынок </b-button>
                  <b-button :disabled="busy" size="sm" class="ml-1" variant="primary" @click="getFC(user)"> Авик </b-button>
                </div>
              </b-overlay>
            </template>
          </b-card>
        </b-card-group>
      </b-tab>
      
      <b-tab title="Миссии" >
        <keep-alive>
          <cmdr-missions ref="missions_ref"></cmdr-missions>
        </keep-alive>
      </b-tab>
      <b-tab title="Всякое" >
        <keep-alive>
          <cmdr-actions ref="actions_ref"></cmdr-actions>
        </keep-alive>
      </b-tab>
      <b-tab title="Торговля" >
        <keep-alive>
          <cmdr-trade ref="trade_ref"></cmdr-trade>
        </keep-alive>
      </b-tab>
      <b-tab title="БортЖурнал" >
        <keep-alive>
          <cmdr-flight-log ref="flightlog_ref"></cmdr-flight-log>
        </keep-alive>
      </b-tab>
    </b-tabs>
  </b-card>

  <b-modal id="squad_ship_loadout-modal" size="xl" hide-header ok-only>
    <ship-loadout :user_id="ship_user_id" :ship_id="ship_id"></ship-loadout>
  </b-modal>

  <b-modal id="squad_ranks-modal" hide-header ok-only>
    <b-card no-body header="Ранги">
      <b-table-simple small striped responsive>
        <b-tbody>
          <b-tr v-for="item,idx in user_ranks" :key="idx">
            <b-th>{{item.name}}</b-th>
            <b-td>{{item.rank}}</b-td>
            <b-td>{{item.progress}}%</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </b-modal>

  <b-modal id="squad_reputation-modal" hide-footer scrollable size="lg" title="Репутация с Фракциями">
    <template #modal-title>
      <b-form-group label="Фильтр" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm">
        <b-input-group size="sm">
          <b-form-input id="filter-input" v-model="faction_filter" type="search" placeholder="Type to Search"></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!faction_filter" @click="faction_filter = ''">Очистить</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </template>
    <b-table :items="user_reputation" :fields="reputation_fields" small responsive head-variant="dark"
             :tbody-tr-class="factionRowClass" :filter="faction_filter">
      <template #cell(name)="row">
        <b-link :to="`/faction/${row.item.id}`">{{row.item.name}}</b-link>
      </template>
    </b-table>
  </b-modal>
  
</div>
</template>

<script>
import { WS } from '@/misc.js';
import CmdrMissions from '@/components/CmdrMissions.vue';
import CmdrActions from '@/components/CmdrActions.vue';
import CmdrTrade from '@/components/CmdrTrade.vue';
import CmdrFlightLog from '@/components/CmdrFlightLog.vue';
import ShipLoadout from "@/components/ShipLoadout.vue";
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'CommanderSquad',
    inject: ['getUserInfo'],
    components: {
        CmdrMissions, CmdrActions, CmdrFlightLog, ShipLoadout, CmdrTrade, 
    },
    data() {
        return {
            activeTab: 0,
            busy: false,
            user_list: [],
            ship_user_id: null,
            ship_id: null,
            user_ranks: {},
            user_reputation: [],
            reputation_fields: [
                { key: 'name', label: 'Фракция', sortable: true, thStyle: {width:"400px"} },
                { key: 'status', label: 'Статус', sortable: true },
                { key: 'reputation', label: 'Репа', sortable: true, class: "text-right",
                  formatter: value => {
                      return (value === null) ? '--' : value.toFixed(2);
                  }
                },
                { key: 'updated', label: 'Обн.', sortable: true,
                  formatter: value => {
                      return (value === null) ? '??' :  moment.unix(value).fromNow(true);
                  }
                },
            ],
            faction_filter: null,
        }
    },
    watch: {
        async $route() {
            await this.refresh();
        }
    },
    async mounted() {
        await this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.user_list = await WS.request('users');
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
        async refreshOne(user) {
            try {
                let data = await WS.request('user', user.id);
                for (let key of ['last_event','profile','ship','fleetcarrier','frozen','rank','progress']) {
                  user[key] = data[key];
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
        
        async getProfiles() {
            this.busy = true
            try {
                await WS.request('profile');
            } catch (err) {
                this.$bvModal.msgBoxOk(`getProfile: ${err}`);
            }
            this.busy = false
            await this.refresh();
        },
        
        async getProfile(user) {
            this.busy = true
            try {
                await WS.request('profile', user.id);
            } catch (err) {
                this.$bvModal.msgBoxOk(`getProfile: ${err}`);
            }
            this.busy = false
            await this.refreshOne(user);
        },
        
        async getJournal(user) {
            this.busy = true
            try {
                var log;
                log = await WS.request('journal', user.id);
                this.$bvModal.msgBoxOk(`Загружено ${log.amount} журналов, ${log.systems} систем.`);
            } catch (err) {
                this.$bvModal.msgBoxOk(`Journal: ${err}`);
            }
            this.busy = false
            await this.refreshOne(user);
        },
        
        async getFC(user) {
            this.busy = true
            try {
                await WS.request('fleetcarrier', user.id);
            } catch (err) {
                this.$bvModal.msgBoxOk(`getFC: ${err}`);
            }
            this.busy = false
            await this.refreshOne(user);
        },
        
        async getMarket(user) {
            this.busy = true
            try {
                await WS.request('market', user.id);
            } catch (err) {
                this.$bvModal.msgBoxOk(`getMarket: ${err}`);
            }
            this.busy = false
        },
        
        async getAll(user) {
            this.busy = true
            try {
                let log = await WS.request('everything', user.id);
                this.$bvModal.msgBoxOk(`Загружено ${log.amount} журналов, ${log.systems} систем.`);
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            }
            this.busy = false
            await this.refreshOne(user);
        },
        
        async refresher(newTabIndex, prevTabIndex) {
            if (prevTabIndex != -1) {
                if (newTabIndex == 0) {
                    await this.refresh();
                } else if (newTabIndex == 1) {
                    this.$refs.missions_ref.$emit('refresh');
                } else if (newTabIndex == 2) {
                    this.$refs.actions_ref.$emit('refresh');
                } else if (newTabIndex == 3) {
                    this.$refs.trade_ref.$emit('refresh');
                } else if (newTabIndex == 4) {
                    this.$refs.flightlog_ref.$emit('refresh');
                }
            }
        },
        
        async frozen(user) {
            let choice = await this.$bvModal.msgBoxConfirm('Вы уверены в своих действиях?', 
                                                            { size: 'sm', buttonSize: 'sm', okVariant: 'danger', 
                                                              okTitle: 'Да', cancelTitle: 'Нет', });
            if (choice) {
                try {
                    await WS.request('user_update', { user_id: user.id, frozen: user.frozen });
                } catch (err) {
                    this.$bvModal.msgBoxOk(`getFC: ${err}`);
                }
            } else {
              user.frozen = !user.frozen;
            }
        },
        showLoadout(user) {
            this.ship_user_id = user.id;
            this.ship_id = user.ship.ship_id;
            this.$bvModal.show("squad_ship_loadout-modal");
        },
        showRanks(user) {
            this.user_ranks = user.rank;
            this.$bvModal.show("squad_ranks-modal");
        },
        async showReps(user) {
            try {
                this.user_reputation = await WS.request('reputations', { user_id: user.id });
                this.$bvModal.show("squad_reputation-modal");
            } catch (err) {
                this.$bvModal.msgBoxOk(`reps: ${err}`);
            }
        },
        factionRowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status == 'Союзная') return 'table-success'
            if (item.status == 'Дружественная') return 'table-info'
            if (item.status == 'Приветливая') return 'table-primary'
            if (item.status == 'Недружественная') return 'table-warning'
            if (item.status == 'Враждебная') return 'table-danger'
        },
    },
    filters: {
        num2text(value) {
            // return new Intl.NumberFormat("ru", {notation:'scientific'}).format(value);
            if (value === null || value === undefined) return  '--'
            if (value >= 1000000000) {
                return (value / 1000000000).toFixed(2) + ' млрд.'
            } else if (value >= 1000000) {
                return (value / 1000000).toFixed(2) + ' млн.'
            } else if (value >= 1000) {
                return (value / 1000).toFixed(2) + ' тыс.'
            } else {
                return (value === null) ? '--' : value.toFixed(2);
            }
        },
        timestamp(ts) {
            return moment.unix(ts).format("DD.MM.YYYY HH:mm");
        },
    },
}
</script>

<style>
.cmdrcard {
    width: 300px;
}
</style>
